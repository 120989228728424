// extracted by mini-css-extract-plugin
export var aligner = "rate-module--aligner--1ba19";
export var disclaimer = "rate-module--disclaimer--e5a26";
export var disclaimer_header = "rate-module--disclaimer_header--e4cb5";
export var horizontalAligner = "rate-module--horizontalAligner--902c2";
export var large_header = "rate-module--large_header--e7743";
export var lesson = "rate-module--lesson--694ec";
export var lesson_left = "rate-module--lesson_left--bc59c";
export var lesson_right = "rate-module--lesson_right--6b173";
export var serviceText = "rate-module--serviceText--cafe3";
export var services = "rate-module--services--b7a0b";