import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/rate.module.css'
import * as mobileStyles from '../styles/mobile/rate.module.css'
import { useWindowDimensions } from '../components/Window'
import Seo from '../components/SEO'

const RatesOnly = () => {
  const { width } = useWindowDimensions()

  let thisStyle;
  if (width > 850) thisStyle = styles;
  else thisStyle = mobileStyles;

  return (
    <>
      <div className={thisStyle.disclaimer}>
        <h1 className={thisStyle.disclaimer_header}>Regarding Booking and Payment</h1>
        <p>
          Smart & Safe Driving School requires all booked driving lessons and packages to be paid by Interac e-Transfer after confirmation by student and instructor.
          Failure to do so within 24 hours of confirmation will cancel the booking and open it to another student on the wait list.
        </p>
      </div>
      <div>
        <h1 className={thisStyle.large_header}>
          Individual Lessons
        </h1>
        <div className={thisStyle.aligner}>
          <div className={`${thisStyle.lesson} ${thisStyle.lesson_left}`}>
            <h2 style={{ marginTop: "25px", marginBottom: "10px" }}>1 Hour</h2>
            <h1>
              $65.00
            </h1>
          </div>
          <div className={`${thisStyle.lesson} ${thisStyle.lesson_right}`}>
            <h2 style={{ marginTop: "25px", marginBottom: "10px" }}>1.5 Hours</h2>
            <h1>
              $95.00
            </h1>
          </div>
        </div>
        <p style={{ textAlign: "center", margin: "0 auto" }}>*Rate may vary based on your location</p>
        <p style={{ textAlign: "center", margin: "0 auto" }}>*Tax included</p>
      </div>
      <div style={{ marginTop: "4em" }}>
        <h1 className={thisStyle.large_header}>
          Package Lessons
        </h1>
        <div className={thisStyle.aligner}>
          <div className={`${thisStyle.lesson} ${thisStyle.lesson_left}`}>
            <h2 style={{ marginTop: "25px", marginBottom: "10px" }}>10 Hours</h2>
            <h1>
              $600.00
            </h1>
          </div>
          <div className={`${thisStyle.lesson} ${thisStyle.lesson_right}`}>
            <h2 style={{ marginTop: "25px", marginBottom: "10px" }}>12 Hours</h2>
            <h1>
              $700.00
            </h1>
          </div>
        </div>
        <p style={{ textAlign: "center", margin: "0 auto" }}>*Rate may vary based on your location</p>
        <p style={{ textAlign: "center", margin: "0 auto" }}>*Tax included</p>
      </div>
      <div style={{ marginTop: "4em" }}>
        <h1 className={thisStyle.disclaimer_header}
          style={{ textAlign: "center", margin: "0 auto" }}>
          Road Test Vehicle Rental
        </h1>
        <p style={{ textAlign: "center", margin: "0 auto" }}>Includes 45 Minute Warm Up</p>
        <div className={`${thisStyle.lesson}`}
          style={{ margin: "auto", width: "180px", height: "126px" }}>
          <h1 style={{ textAlign: "center", verticalAlign: "middle", lineHeight: "126px" }}>
            $160.00
          </h1>
        </div>
      </div>
    </>
  )
}

const Service = ({ thisStyle, serviceName, serviceImgPath, serviceImgAlt, leftOrRight }) => {
  if (leftOrRight === 'left')
    return (
      <div className={thisStyle.horizontalAligner}>
        <p className={thisStyle.serviceText}>{serviceName}</p>
        <img src={serviceImgPath} alt={serviceImgAlt} style={{ marginLeft: "4em" }} />
      </div>
    )
  else if (leftOrRight === 'right')
    return (
      <div className={thisStyle.horizontalAligner}>
        <img src={serviceImgPath} alt={serviceImgAlt} style={{ marginRight: "4em" }} />
        <p className={thisStyle.serviceText}>{serviceName}</p>
      </div>
    )
  else
    return (<></>)
}

const Services = () => {
  const { width } = useWindowDimensions()

  let thisStyle;
  if (width > 850) thisStyle = styles;
  else thisStyle = mobileStyles;

  return (
    <div className={thisStyle.services}>
      <h1 className={thisStyle.large_header}>Services</h1>
      <Service
        thisStyle={thisStyle}
        serviceName="Private one-on-one lessons with bilingual instructor (Tagalog and English)."
        serviceImgPath="/i1.png"
        serviceImgAlt="i1"
        leftOrRight="left"
      />
      <Service
        thisStyle={thisStyle}
        serviceName="Pick up location: Lansdowne, Joyce, Oakridge Station"
        serviceImgPath="/i2.png"
        serviceImgAlt="i2"
        leftOrRight="right"
      />
      <Service
        thisStyle={thisStyle}
        serviceName="Class 5/7 Training in a 2013 Toyota Prius with Dual Control Brake and all power options."
        serviceImgPath="/i3.png"
        serviceImgAlt="i3"
        leftOrRight="left"
      />
      <Service
        thisStyle={thisStyle}
        serviceName="Defensive, Smart & Safe driving techniques."
        serviceImgPath="/i4.png"
        serviceImgAlt="i4"
        leftOrRight="right"
      />
      <Service
        thisStyle={thisStyle}
        serviceName="Available Monday to Saturday."
        serviceImgPath="/i5.png"
        serviceImgAlt="i5"
        leftOrRight="left"
      />
    </div>
  )
}

const Rates = () => {
  return (
    <Layout>
      <Seo title={`Rates & Services`} />
      <RatesOnly />
      <Services />
    </Layout>
  )
}

export default Rates